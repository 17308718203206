import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { GroupService } from "../../../shared/services/group.service";
import { UserService } from "../../../shared/services/user.service";
import { GoalService } from "../../../shared/services/goal.service";
import { Exercise, Goal } from "../../../shared/services/goal";
import { User } from "../../../shared/services/user";
import { Router } from '@angular/router';
import { Group } from "../../../shared/services/group";
import { ConfirmDialogService } from '../../../shared/confirm-dialog/confirm-dialog.service';
import { UserMediaComponent } from '../user-media/user-media.component';
import { DataService } from "../../../shared/services/data.service";

@Component({
  selector: 'app-user-goal-list',
  templateUrl: './user-goal-list.component.html',
  styleUrls: ['./user-goal-list.component.scss']
})

export class UserGoalListComponent implements OnInit {

  dataLoaded: Boolean = false;
  user = undefined;
  userData: User = undefined;
  groupData: Group = undefined;
  group = "";
  showInfo = true;
  userHasGroup = false;
  currentPart = 0;
  unlockCode = "";
  currentGoals: Goal[] = [];
  groupSelectionSubmitted = false;
  unlockCodeSubmitted = false;
  allGoalsDone = false;

  // @ViewChild(UserQuestionnaireComponent, { static: false }) questionnaireComponent: UserQuestionnaireComponent;
  @ViewChild(UserMediaComponent, { static: false }) mediaComponent: UserMediaComponent;


  constructor(
    public authService: AuthService,
    public groupService: GroupService,
    public userService: UserService,
    private router: Router,
    public goalService: GoalService,
    private dialogService: ConfirmDialogService,
    private data: DataService
  ) {
  }


  ngOnInit() {
    this.loadData();
    this.data.currentMessage.subscribe(message => { if (message == "showInfo") { this.showInfo = true; } });
  }


  loadData() {
    this.dataLoaded = false;
    this.authService.observableUserDetails.subscribe(value => {
      if (value != null && value != undefined) {
        this.user = value;
        this.authService.GetUserData().subscribe(userData => {
          if (userData != null) {
            this.group = userData["group"];
            this.userData = userData as User;
            if (userData["group"] != "") {
              this.userHasGroup = true;
              if (localStorage.getItem("ldapp-info") != null) {
                this.showInfo = false;
                this.currentPart = this.userData.currentPart;
                this.getGroupGoals(this.group).then().catch((err) => console.log('Something went wrong: ' + err));
              }
            }
            this.dataLoaded = true;
          }
        });
      }
    });
  }

  /**
   * Hide Info
   */
  hideInfo() {
    localStorage.setItem("ldapp-info", "true");
    this.showInfo = false;
    this.loadData();
  }


  /**
 * Check if group exists, join if true
 */
  joinGroup() {
    this.groupSelectionSubmitted = true;
    this.groupService.CheckGroup(this.group).subscribe(res => {
      this.groupSelectionSubmitted = false;

      const group = res as Group[]
      if (group.length == 0) {
        this.showAlert("Fehler", "Gruppe nicht gefunden.", "OK", "");
      }
      else {
        this.userService.UpdateUser(this.user.uid, group[0].uid).subscribe(success => {
          if (success) {

            this.userHasGroup = true;
            this.getGroupGoals(group[0].uid).then().catch((err) => console.log('Something went wrong: ' + err));

            this.userData.logs.push({
              type: 'info',
              message: 'User joined group: ' + group[0].title,
              created: Date.now()
            })
            this.userService.AddUserLog(this.userData.uid, this.userData.logs).toPromise().then(res => { });
          }
        });
      }
    });
  }


  /**
 * Get current goal exercise of assigned group
 * @param name 
 */
  async getGroupGoals(groupuid: string) {

    // if (localStorage.getItem("ldapp-info") == null) {
    //   this.showInfo = true;
    //   return [];
    // } else {
    // this.showInfo = false;
    return new Promise(async (resolve, rejects) => {
      this.groupService.GetGroup(groupuid).subscribe(res => {
        const cGroup = res as Group;
        this.groupData = cGroup;
        const gGoals = this.userData.currentPart == 0 ? cGroup.firstGoalGroup.map(g => this.goalService.GetGoal(g).toPromise()) : cGroup.secondGoalGroup.map(g => this.goalService.GetGoal(g).toPromise());
        Promise.all(gGoals).then((values) => {
          this.currentGoals = values as Goal[];
          this.checkAllGoalsDone();
          resolve([]);
        }).catch((err) => rejects(err));
      });
    });
    // }


  }


  /**
  * Show goal info
  * @param goal 
  */
  async showGoalInfo(goal: Goal) {

    this.dialogService.open({
      title: "Instruktion",
      message: goal.instruction,
      cancelText: null,
      confirmText: "OK",
      width: '800px'
    });

  }


  /**
   * Open a goal
   * @param goal 
   */
  async openGoal(goal) {

    this.userData.logs.push({
      type: 'info',
      message: 'User opened goal: ' + goal.title,
      created: Date.now()
    })

    await this.userService.AddUserLog(this.userData.uid, this.userData.logs).toPromise().then(res => { });

    this.router.navigate(['/dashboard/user-goal/' + goal.uid], {
      state: {
        goal: JSON.stringify(goal),
        user: JSON.stringify(this.userData)
      }
    });
  }


  /**
   * Check if exercise is done
   * @param exercise 
   * @returns 
   */
  isExerciseDone(exercise: Exercise) {
    var response = this.userData.responses.find(x => x.exerciseId == exercise.uid);
    return response == null ? false : response.completed ? true : false;
  }


  /**
 * Check if goal is done
 * @param exercise 
 * @returns 
 */
  isGoalDone(goal: Goal) {
    var response = this.userData.responses.filter(x => x.goalId == goal.uid && x.completed);
    return response.length == goal.exercises.length ? true : false;
  }

  /**
   * Check if all goals are done
   */
  checkAllGoalsDone() {

    // Calculate the number of goals that are done
    var numberGoalsDone = 0;
    this.currentGoals.forEach(g => {
      if (this.isGoalDone(g)) {
        numberGoalsDone++;
      }
    });

    // Increment part if all current goals are done
    if (numberGoalsDone == this.currentGoals.length) {

      // Unlock all media if all goals finished
      if (this.currentPart == 1) {
        this.mediaComponent.setCurrentGoal(null);
      }
      this.allGoalsDone = true;
    }
    else {
      this.mediaComponent.setCurrentGoal(this.currentGoals);
      this.allGoalsDone = false;
    }

  }


  /**
   * Get unlock url
   * @returns 
   */
  getUnlockUrl() {
    if (this.groupData != undefined) {
      return this.groupData.unlockUrl;
    }
    else {
      return '';
    }
  }


  /**
   * Check unlock code
   */
  checkCode() {
    if (this.unlockCode == this.groupData.unlockCode) {
      this.unlockCodeSubmitted = true;
      this.userService.UpdateUserPart(this.userData.uid, this.userData.currentPart + 1).subscribe(res => {
        this.unlockCodeSubmitted = false;
        this.loadData();
      });
    }
    else {
      this.showAlert("Fehler", "Der eingegebene Code ist inkorrekt.", "OK", "");
    }
  }

  /**
 * Get final url
 * @returns 
 */
  getFinalUrl() {
    if (this.groupData != undefined) {
      return this.groupData.finalUrl;
    }
    else {
      return '';
    }
  }


  /**
   * Close the goal tab info
   */
  closeGoalTabInfo() {
    localStorage.setItem("ldapp-goal-tab-info", "true");
  }

  /**
 * Close the media tab info
 */
  closeMediaTabInfo() {
    localStorage.setItem("ldapp-media-tab-info", "true");
  }


  /**
   * Check if we should show the goal tab info
   */
  showGoalTabInfo() {
    return localStorage.getItem("ldapp-goal-tab-info") != null ? false : true
  }

  /**
 * Check if we should show the media tab info
 */
  showMediaTabInfo() {
    return localStorage.getItem("ldapp-media-tab-info") != null ? false : true
  }

  /**
 * Show error alert
 * @param title 
 * @param message 
 * @param confirmText 
 * @param cancelText 
 */
  showAlert(title, message, confirmText, cancelText) {

    this.dialogService.open({
      title: title,
      message: message,
      cancelText: cancelText,
      confirmText: confirmText,
      width: '400px'
    });

  }


}
