import { Component } from '@angular/core';
import { QuestionnaireService } from '../../../shared/services/questionnaire.service';
import { Questionnaire } from '../../../shared/services/questionnaire';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from "../../../shared/services/user.service";
import { User } from "../../../shared/services/user";
import { QuestionnaireResponse } from "../../../shared/services/response";
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-questionnaire-details',
  templateUrl: './user-questionnaire-details.component.html',
  styleUrls: ['./user-questionnaire-details.component.scss']
})

export class UserQuestionnaireDetailsComponent {

  dataSource: MatTableDataSource<any>;
  questionnaire: Questionnaire = undefined;
  user: User = undefined;
  questionResponse: number[] = [];

  constructor(
    private questionnaireService: QuestionnaireService,
    public userService: UserService,
    public router: Router
  ) {

    if (this.router.getCurrentNavigation() != undefined &&
      this.router.getCurrentNavigation().extras != undefined &&
      this.router.getCurrentNavigation().extras.state != undefined) {
      this.questionnaire = JSON.parse(this.router.getCurrentNavigation().extras.state.questionnaire);
      this.user = JSON.parse(this.router.getCurrentNavigation().extras.state.user);
    }
    else {
      this.router.navigate(['dashboard']);
    }
  }


  // Submit filled in questionnaires
  async submitQuestionnaire() {

    this.user.logs.push({
      type: 'info',
      message: 'User finished questionnaire: ' + this.questionnaire.title,
      created: Date.now()
    })

    await this.userService.AddUserLog(this.user.uid, this.user.logs).toPromise().then(res => { });

    const response: QuestionnaireResponse = {
      questionnaireId: this.questionnaire.uid,
      questionResponse: this.questionResponse,
      answered: Date.now()
    }

    // this.user.questionnaires.push(response);
    // this.userService.UpdateUserQuestionnaireInfo(this.user.uid, this.user.questionnaires).subscribe(res => {
    //   this.router.navigate(['/dashboard/user-goals']);
    // });
  }

}
