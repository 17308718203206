import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Group } from '../../../../shared/services/group';
import { Exercise, Goal } from '../../../../shared/services/goal';
import { User } from '../../../../shared/services/user';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { MatSnackBar } from '@angular/material/snack-bar';
import { GroupService } from 'src/app/shared/services/group.service';
import { GoalService } from 'src/app/shared/services/goal.service';
import { GroupEditorComponent } from '../group-editor/group-editor.component';

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss']
})

export class GroupDetailsComponent {

  firstGoalGroup: any = [];
  secondGoalGroup: any = [];
  availableGoals = [];
  fgoals: Goal[] = [];
  sgoals: Goal[] = [];
  nfgoals: number = 0;
  nsgoals: number = 0;
  dataSource: MatTableDataSource<User>;
  groupMembers: User[] = [];

  displayedColumns: any[] = [
    'title',
    'goal',
    'actions'
  ];

  constructor(
    private groupApi: GroupService,
    public goalApi: GoalService,
    private router: Router,
    public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private snackBar: MatSnackBar
  ) {

    if (this.router.getCurrentNavigation() != undefined &&

      // Read state info passed via route
      this.router.getCurrentNavigation().extras != undefined &&
      this.router.getCurrentNavigation().extras.state != undefined) {
      this.group = JSON.parse(this.router.getCurrentNavigation().extras.state.group);

      // Get goal IDS from group
      if (this.group != null) {
        this.firstGoalGroup = this.group.firstGoalGroup as string[];
        this.secondGoalGroup = this.group.secondGoalGroup as string[];

        this.nfgoals = this.firstGoalGroup.length;
        this.nsgoals = this.secondGoalGroup.length;
      }

      // Get goal details
      this.goalApi.GetGoalList().subscribe(goals => {
        this.availableGoals = goals as Goal[];

        // Load goals infos from all available goals
        this.firstGoalGroup.forEach(goal => {
          this.fgoals.push(this.availableGoals.find(g => g.uid == goal))
        });

        // Load goals infos from all available goals
        this.secondGoalGroup.forEach(goal => {
          this.sgoals.push(this.availableGoals.find(g => g.uid == goal))
        });

      });

      // Get group members
      this.getGroupMembers(this.group.uid);

    }
    else {
      this.router.navigate(['dashboard']);
    }

  }

  group: Group = {
    uid: '',
    title: '',
    firstGoalGroup: [],
    secondGoalGroup: [],
    unlockCode: '',
    unlockUrl: '',
    finalUrl: '',
    created: Date.now(),
    updated: Date.now()
  }


  /**
   * Update first goals array
   */
  public updateNumberFirstGoals() {
    // Check if we already have defined values
    var tempValues: Goal[] = [];
    if (this.fgoals.length > 0) {
      tempValues = JSON.parse(JSON.stringify(this.fgoals));
    }

    // Reset value array and fill with description object
    this.fgoals = [];
    var goalObject: Goal = {
      uid: '',
      title: '',
      instruction: '',
      exercises: [],
      created: Date.now(),
      updated: Date.now()
    };

    for (var i = 0; i < this.nfgoals; i++) {
      if (i < tempValues.length) {
        this.fgoals.push(JSON.parse(JSON.stringify(tempValues[i])));
      }
      else {
        this.fgoals.push(JSON.parse(JSON.stringify(goalObject)));
      }
    }
  }


  /**
   * Update first goals array
   */
  public updateNumberSecondGoals() {
    // Check if we already have defined values
    var tempValues: Goal[] = [];
    if (this.sgoals.length > 0) {
      tempValues = JSON.parse(JSON.stringify(this.sgoals));
    }

    // Reset value array and fill with description object
    this.sgoals = [];
    var goalObject: Goal = {
      uid: '',
      title: '',
      instruction: '',
      exercises: [],
      created: Date.now(),
      updated: Date.now()
    };

    for (var i = 0; i < this.nsgoals; i++) {
      if (i < tempValues.length) {
        this.sgoals.push(JSON.parse(JSON.stringify(tempValues[i])));
      }
      else {
        this.sgoals.push(JSON.parse(JSON.stringify(goalObject)));
      }
    }
  }


  /**
   * Save group
   */
  saveGroup() {
    this.group.firstGoalGroup = this.fgoals.map(u => u.uid);
    this.group.secondGoalGroup = this.sgoals.map(u => u.uid);
    this.groupApi.AddGroup(this.group).subscribe(() => {
      this.router.navigate(['dashboard']);
    });
  }


  /**
   * Edit goal title
   */
  editGroupTitle() {
    const dialogRef = this.dialog.open(GroupEditorComponent, {
      data: {
        group: this.group
      },
      width: "400px"
    });
    dialogRef.afterClosed().subscribe(confirmresult => { });
  }


  /**
   * Change value for first goal group
   * @param goal 
   * @param index 
   */
  changeFValue(goal: Goal, index: number) {
    this.fgoals[index] = goal;
  }

  /**
 * Change value for second goal group
 * @param goal 
 * @param index 
 */
  changeSValue(goal: Goal, index: number) {
    this.sgoals[index] = goal;
  }


  /**
   * Get all members of group
   */
  getGroupMembers(groupId: string) {
    this.groupApi.GetGroupMembers(groupId).subscribe(res => {
      this.groupMembers = res as User[];
      this.dataSource = new MatTableDataSource(this.groupMembers)
    });
  }


  /**
   * Download Logs
   */
  downloadLogs(user: User) {
    let csvArray = [];
    csvArray.push('date,type,message');
    user.logs.forEach(log => csvArray.push(log.created + ',' + log.type + ',' + log.message));

    // EXPORT
    // -----------
    var a = document.createElement('a');
    var blob = new Blob([csvArray.join("\n")], { type: 'text/csv' }),
      url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = Date.now() + " - " + user.uid + " - Logs.csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  /**
   * Export group
   */
  exportGroup() {

    // ADD HEADERS
    // -----------
    let csvArray = [];
    let mArrayHeader = [];
    mArrayHeader.push("Participant");
    mArrayHeader.push("E-Mail");
    mArrayHeader.push("Group");
    mArrayHeader.push("Started");
    mArrayHeader.push("Completed Exercises");

    // Add responses for first part
    this.fgoals.forEach(g => {
      g.exercises.forEach((ex, exindex) => {
        ex.instructions.forEach((val, index) => {
          mArrayHeader.push("Part 1 - " + g.title + " - Exercise " + exindex + " - Instruction " + index);
        });
        ex.questions.forEach((val, index) => {
          mArrayHeader.push("Part 1 - " + g.title + " - Exercise " + exindex + " - Question " + index);
        });
      })
    });

    // Add responses for second part
    this.sgoals.forEach(g => {
      g.exercises.forEach((ex, exindex) => {
        ex.instructions.forEach((val, index) => {
          mArrayHeader.push("Part 2 - " + g.title + " - Exercise " + exindex + " - Instruction " + index);
        });
        ex.questions.forEach((val, index) => {
          mArrayHeader.push("Part 2 - " + g.title + " - Exercise " + exindex + " - Question " + index);
        });
      })
    });

    // Push Headers
    csvArray.push(mArrayHeader.join(","))


    // ADD CONTENT
    // -----------
    this.groupMembers.forEach((m, mindex) => {
      let vArray = Array.from(''.repeat(mArrayHeader.length));
      vArray[0] = m.uid;
      vArray[1] = m.email;
      vArray[2] = this.group.title;
      vArray[3] = m.logs.find(l => l.message.includes("User joined group")).created.toString();
      vArray[4] = m.responses.length.toString();

      var vArrayIndex = 5;
      // Add responses for first part
      this.fgoals.forEach(g => {
        g.exercises.forEach((ex, exindex) => {
          let response = m.responses.find(r => r.goalId == g.uid && r.exerciseId == ex.uid && r.completed);
          // Fill up values if response exist
          ex.instructions.forEach((val, index) => {
            vArray[vArrayIndex] = response != null ? response.instructionResponse[index] : '';
            vArrayIndex += 1;
          });
          ex.questions.forEach((val, index) => {
            vArray[vArrayIndex] = response != null ? response.questionResponse[index].toString() : '';
            vArrayIndex += 1;
          });
        });
      });

      // Add responses for second part
      this.sgoals.forEach(g => {
        g.exercises.forEach((ex, exindex) => {
          let response = m.responses.find(r => r.goalId == g.uid && r.exerciseId == ex.uid && r.completed);
          // Fill up values if response exist
          ex.instructions.forEach((val, index) => {
            vArray[vArrayIndex] = response != null ? response.instructionResponse[index] : '';
            vArrayIndex += 1;
          });
          ex.questions.forEach((val, index) => {
            vArray[vArrayIndex] = response != null ? response.questionResponse[index].toString() : '';
            vArrayIndex += 1;
          });
        });
      });

      // Push to array
      csvArray.push(vArray.join(","))
    });


    // EXPORT
    // -----------
    var a = document.createElement('a');
    var blob = new Blob([csvArray.join("\n")], { type: 'text/csv' }),
      url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = Date.now() + " - " + this.group.title + " - Export.csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();

  }
}
