import { Component } from '@angular/core';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {

  selectedTabIndex: number = 0;

  constructor() {
    // Check if we have a stored tab
    if (localStorage.getItem("ldapp-selected-admin-tab") != null) {
      this.selectedTabIndex = parseInt(localStorage.getItem("ldapp-selected-admin-tab"));
    }
  }


  /**
   * Save current tab 
   * @param index 
   */
  selectedIndexChange(index: number) {
    setTimeout(() => {
      this.selectedTabIndex = index;
      localStorage.setItem("ldapp-selected-admin-tab", index.toString());
    });
  }

}
