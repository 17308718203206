import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { Goal } from '../../../../shared/services/goal';
import { GoalService } from '../../../../shared/services/goal.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from "../../../../shared/services/auth.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";


@Component({
  selector: 'app-goal-editor',
  templateUrl: './goal-editor.component.html',
  styleUrls: ['./goal-editor.component.scss']
})
export class GoalEditorComponent {

  buttonTitle = "Erstellen";

  @Output() goalAdded = new EventEmitter();

  constructor(
    private goalApi: GoalService,
    private snackBar: MatSnackBar,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: {
      goal: Goal,
    },
    private mdDialogRef: MatDialogRef<GoalEditorComponent>
  ) { }


  /**
   * Get button title
   */
  getButtonTitle() {
    if (this.data.goal.uid != '') {
      return "Aktualisieren";
    }
    else {
      return "Erstellen"
    }
  }


  /**
   * Create a goal
   */
  createGoal() {
    if (this.data.goal.uid == '') {
      this.goalApi.AddGoal(this.data.goal).subscribe(() => {
        this.mdDialogRef.close();
      });
    }
    else {
      if (this.data.goal.title != '') {
        this.goalApi.AddGoal(this.data.goal).subscribe(() => {
          this.mdDialogRef.close();
        });
      }
    }
  }

}
