import { BrowserModule } from '@angular/platform-browser';

/* Angular material */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './material.module';
import { NgModule } from '@angular/core';

// Reactive Form
import { ReactiveFormsModule } from "@angular/forms";

// App routing modules
import { AppRoutingModule } from './shared/routing/app-routing.module';

// Alert component
import { ConfirmDialogModule } from './shared/confirm-dialog/confirm-dialog.module'
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component'

// App components
import { AppComponent } from './app.component';
import { SignInComponent } from './components/authentication/sign-in/sign-in.component';
import { SignUpComponent } from './components/authentication/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/authentication/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/authentication/verify-email/verify-email.component';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { GoalListComponent } from './components/admin/goal-editor/goal-list/goal-list.component';
import { GoalDetailsComponent } from './components/admin/goal-editor/goal-details/goal-details.component';
import { GoalEditorComponent } from './components/admin/goal-editor/goal-editor/goal-editor.component';
import { ExerciseEditorComponent } from './components/admin/goal-editor/exercise-editor/exercise-editor.component';


import { GroupEditorComponent } from './components/admin/group-editor/group-editor/group-editor.component';
import { GroupDetailsComponent } from './components/admin/group-editor/group-details/group-details.component';
import { GroupListComponent } from './components/admin/group-editor/group-list/group-list.component';

import { UserGoalDetailsComponent } from './components/user-goals/user-goal-details/user-goal-details.component';
import { UserGoalListComponent } from './components/user-goals/user-goal-list/user-goal-list.component';
import { UserMediaComponent } from './components/user-goals/user-media/user-media.component';
import { UserQuestionnaireComponent } from './components/user-goals/user-questionnaires/user-questionnaires.component';
import { UserQuestionnaireDetailsComponent } from './components/user-goals/user-questionnaire-details/user-questionnaire-details.component';


import { MediaEditorComponent } from './components/admin/media-editor/media-editor/media-editor.component';
import { MediaListComponent } from './components/admin/media-editor/media-list/media-list.component';

import { QuestionnaireEditorComponent } from './components/admin/questionnaire-editor/questionnaire-editor/questionnaire-editor.component';
import { QuestionnaireListComponent } from './components/admin/questionnaire-editor/questionnaire-list/questionnaire-list.component';
import { QuestionnaireDetailsComponent } from './components/admin/questionnaire-editor/questionnaire-details/questionnaire-details.component';

import { AdminComponent } from './components/admin/admin.component';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { MaterialFileInputModule } from 'ngx-material-file-input';

// Firebase services + enviorment module
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';

// Auth service
import { AuthService } from "./shared/services/auth.service";
import { GoalService } from './shared/services/goal.service';
import { MediaService } from './shared/services/media.service';
import { DataService } from './shared/services/data.service';
import { FlexLayoutModule } from '@angular/flex-layout';

import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';


@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    DashboardComponent,
    GoalListComponent,
    GoalDetailsComponent,
    GoalEditorComponent,
    ExerciseEditorComponent,
    GroupListComponent,
    GroupDetailsComponent,
    UserGoalDetailsComponent,
    UserGoalListComponent,
    UserMediaComponent,
    UserQuestionnaireComponent,
    UserQuestionnaireDetailsComponent,
    QuestionnaireDetailsComponent,
    QuestionnaireEditorComponent,
    QuestionnaireListComponent,
    MediaEditorComponent,
    MediaListComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    GroupEditorComponent,
    AdminComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    ConfirmDialogModule,
    MatFileUploadModule,
    MaterialFileInputModule
  ],
  entryComponents: [
    ConfirmDialogComponent,
    GoalEditorComponent,
    GroupEditorComponent,
    MediaEditorComponent,
    QuestionnaireEditorComponent
  ],
  providers: [
    AuthService,
    AngularFireAuthGuard,
    GoalService,
    MediaService,
    DataService
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }