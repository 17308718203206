import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { map, take } from 'rxjs/operators';

@Injectable()

export class ConfirmDialogService {

  constructor(
    private dialog: MatDialog
  ) { }

  dialogRef: MatDialogRef<ConfirmDialogComponent> = undefined;

  /**
   * Open the dialog
   * @param options 
   */
  public open(options) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: options.title,
        message: options.message,
        cancelText: options.cancelText,
        confirmText: options.confirmText
      },
      width: options.width
    });
  }


  /**
   * Dialog confirmation
   */
  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
      return res;
    }));
  }

}