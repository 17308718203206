import { Injectable } from '@angular/core';
import { AuthService } from "./auth.service";
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})

export class MediaService {

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public authService: AuthService,
    private storage: AngularFireStorage
  ) { }

  /**
   * Upload Media
   * @param group 
   */
  UploadMedia(name: string, goalIds: string[], file: File) {
    const uploadMedia = new Observable(observer => {

      if (file != undefined) {
        this.storage.upload('Lernziele/' + name, file).then(() => {
          // Add goal metadata
          this.storage.ref('Lernziele/' + name).updateMetadata({ 'customMetadata': { "goals": goalIds.join(',') } }).toPromise().then(() => {
            observer.next({ success: true, message: '' });
            observer.complete();
          }).catch(function (error) {
            console.error("Error uploading file: ", error);
            observer.next({ success: false, message: JSON.stringify(error) });
            observer.complete();
          });
        })
          .catch(function (error) {
            console.error("Error uploading file: ", error);
            observer.next({ success: false, message: JSON.stringify(error) });
            observer.complete();
          });
      }
      else {
        observer.next({ success: false, message: 'File undefined' });
        observer.complete();
      }

    });

    return uploadMedia;
  }


  /**
   * Get media by id
   * @param id 
   */
  GetMedia(id: string) {
    const mediaGroup = new Observable(observer => {
      observer.next();
      observer.complete();
    });

    return mediaGroup;
  }


  /**
   * Download media
   * @param uid 
   * @param name 
   */
  DownloadMedia(fullPath: string) {
    const fileUrl = new Observable(observer => {
      this.storage.ref(fullPath).getDownloadURL().toPromise().then(ref => {
        observer.next(ref);
        observer.complete();
      })
        .catch(function (error) {
          observer.error(error);
          observer.complete();
        });
    });
    return fileUrl
  }


  /**
  * Get all media 
  * @param id 
  */
  GetLearningGoalMedia(goalIds: string[]) {
    const mediaGroup = new Observable(observer => {
      this.storage.ref('Lernziele').listAll().toPromise().then(list => {
        Promise.all(list.items.map(item => item.getMetadata())).then(results => {
          let mediaList = [];
          // Check if media contains goal meta data
          if (goalIds != null) {
            results.forEach(res => {
              let customMediaData = res["customMetadata"];
              // Always display media that has no goal defined
              if (customMediaData == undefined || customMediaData["goals"] == "") {
                mediaList.push(res);
              }
              else {
                let goalArray = customMediaData["goals"].split(',');
                if (goalArray.some(r => goalIds.indexOf(r) >= 0)) {
                  mediaList.push(res);
                };
              }
            })
          } else {
            mediaList = results;
          }

          observer.next(mediaList);
          observer.complete();

        }).catch(function (error) {
          console.log(error);
          observer.error(error);
          observer.complete();
        });
      });
    });

    return mediaGroup;
  }


  /**
 * Get all media 
 * @param id 
 */
  GetAllMedia() {

    const mediaGroup = new Observable(observer => {
      this.storage.ref('Lernziele').listAll().toPromise().then(list => {
        Promise.all(list.items.map(item => item.getMetadata())).then(results => {
          observer.next(results);
          observer.complete();
        }).catch(error => {
          console.log(error);
          observer.error(error);
          observer.complete();
        });

      }).catch(function (error) {
        console.log(error);
        observer.error(error);
        observer.complete();
      });
    });

    return mediaGroup;
  }


  /**
   * Delete media by id
   */
  DeleteMedia(name: string) {

    const deleteMedia = new Observable(observer => {

      this.storage.ref("Lernziele").child(name).delete().toPromise().then(res => {
        observer.next(res);
        observer.complete();
      }).catch(error => {
        console.log(error);
        observer.error(error);
        observer.complete();
      });

    });

    return deleteMedia;
  }

}