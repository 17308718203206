import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { QuestionnaireService } from '../../../shared/services/questionnaire.service';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from "../../../shared/services/user.service";
import { User } from "../../../shared/services/user";
import { Router } from '@angular/router';
import { Questionnaire } from 'src/app/shared/services/questionnaire';

@Component({
  selector: 'app-user-questionnaires',
  templateUrl: './user-questionnaires.component.html',
  styleUrls: ['./user-questionnaires.component.scss']
})

export class UserQuestionnaireComponent {

  dataSource: MatTableDataSource<any>;
  questionnaireData: any = [];
  answeredQuestionnaires: string[] = [];
  @Input() user: User;

  constructor(
    private questionnaireService: QuestionnaireService,
    public userService: UserService,
    public router: Router
  ) {
    this.getAllQuestionnaires();
  }


  displayedColumns: any[] = [
    'title',
    'action'
  ];


  /**
   * Reload medias
   */
  reloadQuestionnaires() {
    this.getAllQuestionnaires();
  }


  /**
   * Load all medias
   */
  getAllQuestionnaires() {
    this.questionnaireService.GetQuestionnaires().subscribe(res => {
      this.questionnaireData = res as any[]
      this.dataSource = new MatTableDataSource(this.questionnaireData);
    })
  }


  /**
   * Disable answered questionnaiers list
   * @param answeredQuestionnairelist 
   */
  setAnsweredQuestionnaires(answeredQuestionnairelist) {
    this.answeredQuestionnaires = answeredQuestionnairelist.map(q => q.questionnaireId)
  }


  /**
   * Check if questionnaire is answered
   * @param questionnaireId 
   */
  isQuestionnaireAnswered(questionnaire: Questionnaire) {
    return this.answeredQuestionnaires.includes(questionnaire.uid);
  }


  /**
   * Open a goal
   * @param goal 
   */
  async openQuestionnaire(questionnaire) {

    this.user.logs.push({
      type: 'info',
      message: 'User opened questionnaire: ' + questionnaire.title,
      created: Date.now()
    })

    await this.userService.AddUserLog(this.user.uid, this.user.logs).toPromise().then(res => { });

    this.router.navigate(['/dashboard/user-questionnaire/' + questionnaire.uid], {
      state: {
        questionnaire: JSON.stringify(questionnaire),
        user: JSON.stringify(this.user)
      }
    });
  }
}
