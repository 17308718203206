import { Component } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { GroupService } from "../../../shared/services/group.service";
import { UserService } from "../../../shared/services/user.service";
import { User } from "../../../shared/services/user";
import { GoalService } from "../../../shared/services/goal.service";
import { Exercise, Goal } from "../../../shared/services/goal";
import { Response } from "../../../shared/services/response";
import { ConfirmDialogService } from '../../../shared/confirm-dialog/confirm-dialog.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-goal-details',
  templateUrl: './user-goal-details.component.html',
  styleUrls: ['./user-goal-details.component.scss']
})
export class UserGoalDetailsComponent {

  user: User = undefined;

  exercises: Exercise[] = [];
  currentExercise: number = 0;
  currentExerciseInfo: Exercise;
  selectedGoal: Goal;
  answersSubmitted = false;

  instructionResponse: string[] = [];
  questionResponse: number[] = [];
  htmlString = '<h1>Hello gowtham</h1>';

  constructor(
    public authService: AuthService,
    public groupService: GroupService,
    public userService: UserService,
    public goalService: GoalService,
    private dialogService: ConfirmDialogService,
    private router: Router
  ) {

    window.scrollTo(0, 0); // Scroll to top

    if (this.router.getCurrentNavigation() != undefined &&
      this.router.getCurrentNavigation().extras != undefined &&
      this.router.getCurrentNavigation().extras.state != undefined) {
      this.user = JSON.parse(this.router.getCurrentNavigation().extras.state.user);
      this.selectedGoal = JSON.parse(this.router.getCurrentNavigation().extras.state.goal);
      this.exercises = this.selectedGoal.exercises as Exercise[];
      this.getCurrentExerciseInfo();
    }
    else {
      this.router.navigate(['dashboard']);
    }
  }


  /**
   * Get current goal exercise of assigned group
   * @param name 
   */
  getCurrentExerciseInfo() {

    this.currentExercise = this.user.responses.filter(x => x.goalId == this.selectedGoal.uid && x.completed).length;
    this.currentExerciseInfo = this.exercises[this.currentExercise];

    // Init instruction response array
    this.instructionResponse.length = 0;
    for (let i = 0; i < this.currentExerciseInfo.instructions.length; i++) {
      this.instructionResponse.push("");
    }

    // Init instruction response array
    this.questionResponse.length = 0;
    for (let i = 0; i < this.currentExerciseInfo.questions.length; i++) {
      this.questionResponse.push(-1);
    }

    // Check if exercise already exists > Load saved data
    var responseIndex = this.user.responses.findIndex(e => e.exerciseId == this.currentExerciseInfo.uid && !e.completed);
    if (responseIndex >= 0) {
      this.instructionResponse = this.user.responses[responseIndex].instructionResponse;
      this.questionResponse = this.user.responses[responseIndex].questionResponse;
    }
  }


  /**
   * Get Progress
   */
  getGoalProgress() {
    return (this.currentExercise) / this.selectedGoal.exercises.length * 100;
  }

  /**
   * Get Progress Text
   */
  getGoalProgressText() {
    return "Übung " + (this.currentExercise + 1) + " von " + this.selectedGoal.exercises.length
  }

  /** 
   * Check if all responses for all instructions were provided
   */
  getAllInstructionsReplied() {
    return this.instructionResponse.filter(x => x !== "").length == this.currentExerciseInfo.instructions.length;
  }

  /** 
   * Check if all responses for all instructions were provided
   */
  // getAllQuestionsReplied() {
  //   return this.questionResponse.filter(x => x >= 0).length == this.currentExerciseInfo.questions.length;
  // }


  /**
   * Save the exercise
   */
  async saveExercise() {

    this.answersSubmitted = true;

    this.user.logs.push({
      type: 'info',
      message: 'User saved exercise: ' + this.currentExerciseInfo.title + ' of goal ' + this.selectedGoal.title,
      created: Date.now()
    })

    await this.userService.AddUserLog(this.user.uid, this.user.logs).toPromise().then(res => { });

    // Create response
    const response: Response = {
      goalId: this.selectedGoal.uid,
      exerciseId: this.currentExerciseInfo.uid,
      instructionResponse: JSON.parse(JSON.stringify(this.instructionResponse)),
      questionResponse: JSON.parse(JSON.stringify(this.questionResponse)),
      answered: Date.now(),
      completed: false
    }

    // Add response to array or update existing
    var responseIndex = this.user.responses.findIndex(e => e.exerciseId == this.currentExerciseInfo.uid && !e.completed)
    if (responseIndex < 0) {
      this.user.responses.push(response);
    }
    else {
      this.user.responses[responseIndex] = response;
    }

    this.userService.UpdateUserResponses(this.user.uid, this.user.responses).subscribe(res => {
      this.answersSubmitted = false;
      this.router.navigate(['/dashboard/user-goals']);
    });

  }


  /**
   * Submit the exercise
   */
  async submitExercise() {

    if (this.getAllInstructionsReplied()) {
      this.answersSubmitted = true;

      this.user.logs.push({
        type: 'info',
        message: 'User finished exercise: ' + this.currentExerciseInfo.title + ' of goal ' + this.selectedGoal.title,
        created: Date.now()
      })

      await this.userService.AddUserLog(this.user.uid, this.user.logs).toPromise().then(res => { });


      // Create response
      const response: Response = {
        goalId: this.selectedGoal.uid,
        exerciseId: this.currentExerciseInfo.uid,
        instructionResponse: JSON.parse(JSON.stringify(this.instructionResponse)),
        questionResponse: JSON.parse(JSON.stringify(this.questionResponse)),
        answered: Date.now(),
        completed: true
      }

      // Add response to array or update existing
      var responseIndex = this.user.responses.findIndex(e => e.exerciseId == this.currentExerciseInfo.uid && !e.completed)
      if (responseIndex < 0) {
        this.user.responses.push(response);
      }
      else {
        this.user.responses[responseIndex] = response;
      }

      this.userService.UpdateUserResponses(this.user.uid, this.user.responses).subscribe(res => {
        this.answersSubmitted = false;

        this.dialogService.open({
          title: "Vielen Dank",
          message: "Ihre Eingaben wurden erfolgreich gespeichert. Sie können mit der nächsten Übung fortfahren.",
          cancelText: null,
          confirmText: "OK",
          width: '400px'
        });

        this.dialogService.confirmed().subscribe(confirmed => {
          this.router.navigate(['/dashboard/user-goals']);
          window.scrollTo(0, 0);
        });
      });
    }
  }


  /**
  * Show error alert
  * @param title 
  * @param message 
  * @param confirmText 
  * @param cancelText 
  */
  showAlert(title, message, confirmText, cancelText) {

    this.dialogService.open({
      title: title,
      message: message,
      cancelText: cancelText,
      confirmText: confirmText,
      width: '400px'
    });

  }

}
