import { Injectable } from '@angular/core';
import { User } from "../services/user";
import { UserService } from "../services/user.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any = null; // Save logged in user data
  userDetails: any = null;
  observableUserDetails: BehaviorSubject<any>;
  encryptSecretKey = "smDSD$]4LV7#a(2C/BMx8*KANXg~TfTAn3/FYJE!";

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public userService: UserService
  ) {

    this.observableUserDetails = new BehaviorSubject<any>(this.userDetails);

    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        // localStorage.setItem('user', this.encryptData(JSON.stringify(this.userData)));
        this.userDetails = user;
        this.observableUserDetails.next(user);
      }
    });

  }


  /**
   * Sign in with email/password
   * @param email 
   * @param password 
   */
  async SignIn(email, password) {
    return await this.afAuth.signInWithEmailAndPassword(email, password)
      .then(async (result) => {
        this.SetUserData(result.user).then(() => {
          this.observableUserDetails.next(result.user);
          this.router.navigate(['dashboard']);
        });

      });
  }


  /**
   * Sign up with email/password
   * @param email 
   * @param password 
   */
  SignUp(email, password) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        this.SetUserData(result.user).then(() => {
          this.observableUserDetails.next(result.user);
          this.SendVerificationMail();
        });
      })
  }


  /**
   * Send email verfificaiton when new user sign up
   */
  SendVerificationMail() {
    return this.afAuth.currentUser.then(u => u.sendEmailVerification())
      .then(() => {
        this.router.navigate(['verify-email-address']);
      })
  }


  /**
   * Reset Forggot password
   * @param passwordResetEmail 
   */
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail);
  }


  /**
   * Check if user is logged in
   */
  // get isLoggedIn() {
  //   if (localStorage.getItem('user') != null) {
  //     const user = JSON.parse(this.decryptData(localStorage.getItem('user')));
  //     this.userData = user;
  //     this.observableUserDetails.next(user);
  //     return (user !== null && user.emailVerified !== false) ? true : false;
  //   }
  //   else {
  //     return false;
  //   }
  // }


  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  async SetUserData(user) {

    return new Promise(async (resolve) => {

      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
      await userRef.get().toPromise().then((doc) => {

        user.getIdTokenResult().then(async idTokenResult => {

          let roleType = idTokenResult.claims.admin != undefined ? "admin" : "user";

          let dName = "";
          if (doc.data() != undefined && doc.data()["displayName"] != undefined) {
            dName = doc.data()["displayName"];
          }

          let dGroup = "";
          if (doc.data() != undefined && doc.data()["group"] != undefined) {
            dGroup = doc.data()["group"];
          }

          let dResponses = [] as any
          if (doc.data() != undefined && doc.data()["responses"] != undefined) {
            dResponses = doc.data()["responses"];
          }

          let dCurrentPart = 0;
          if (doc.data() != undefined && doc.data()["currentPart"] != undefined) {
            dCurrentPart = doc.data()["currentPart"];
          }

          // // Set Questionnaires
          // let dQuestionnaires = [];
          // if (doc.data() != undefined && doc.data()["questionnaires"] != undefined) {
          //   dQuestionnaires = doc.data()["questionnaires"];
          // }

          // Set Logs
          let dLogs = []
          if (doc.data() != undefined && doc.data()["logs"] != undefined) {
            dLogs = doc.data()["logs"];
            dLogs.push({
              type: 'info',
              message: 'User logged in.',
              created: Date.now()
            })
          }

          const userData: User = {
            uid: user.uid,
            email: user.email,
            displayName: dName,
            role: roleType,
            emailVerified: user.emailVerified,
            group: dGroup,
            responses: dResponses,
            logs: dLogs,
            currentPart: dCurrentPart,
          }

          if (userData.logs.length > 0) {
            await this.userService.AddUserLog(this.userData.uid, userData.logs).toPromise().then(res => { });
          }

          userRef.set(userData, {
            merge: true
          })

        });
      })

      resolve(true);

    });

  }


  /**
   * Get User Data
   */
  GetUserData() {

    // const user = JSON.parse(this.decryptData(localStorage.getItem('user')));
    const userRef = new Observable(observer => {
      this.afs.collection("users")
        .doc(this.userDetails.uid)
        .ref
        .get().then(function (doc) {
          if (doc.exists) {
            observer.next(doc.data());
            observer.complete()
          } else {
            observer.next(null);
            observer.complete()
          }
        }).catch(function (error) {
          observer.next(null);
          observer.complete()
        });
    });

    return userRef;
  }


  /**
   *  Sign out 
   */
  async SignOut() {

    await this.GetUserData().toPromise().then(userData => {
      if (userData != null) {
        let userD = userData as User;
        userD.logs.push({
          type: 'info',
          message: 'User logged out.',
          created: Date.now()
        })

        this.userService.AddUserLog(this.userData.uid, userD.logs).toPromise().then(res => { });
      }
    });

    return this.afAuth.signOut().then(() => {
      // localStorage.removeItem('user');
      this.router.navigate(['sign-in']);
    });

  }


  // /**
  //  * Encrypt Data
  //  * @param data 
  //  */
  // encryptData(data) {
  //   try {
  //     return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }


  // /**
  //  * Decrypt Data
  //  * @param data 
  //  */
  // decryptData(data) {
  //   try {
  //     const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
  //     if (bytes.toString()) {
  //       return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  //     }
  //     return data;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

}