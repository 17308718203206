import { Component, Input } from '@angular/core';
import { MediaService } from '../../../shared/services/media.service';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from "../../../shared/services/user.service";
import { User } from "../../../shared/services/user";
import { Goal } from "../../../shared/services/goal";

@Component({
  selector: 'app-user-media',
  templateUrl: './user-media.component.html',
  styleUrls: ['./user-media.component.scss']
})

export class UserMediaComponent {

  dataSource: MatTableDataSource<any>;
  mediaData: any = [];
  @Input() user: User;

  constructor(
    private mediaService: MediaService,
    public userService: UserService,
  ) {
  }


  displayedColumns: any[] = [
    'title',
    'action'
  ];


  /**
 * Get media for current goal
 * @param current goals 
 */
  setCurrentGoal(currentGoals: Goal[]) {
    if (currentGoals != null) {
      this.mediaService.GetLearningGoalMedia(currentGoals.map(item => item.uid)).subscribe(res => {
        this.mediaData = res as any[]
        this.dataSource = new MatTableDataSource(this.mediaData);
      })
    }
    else {
      this.mediaService.GetLearningGoalMedia(null).subscribe(res => {
        this.mediaData = res as any[]
        this.dataSource = new MatTableDataSource(this.mediaData);
      })
    }
  }


  /**
   * Download media
   * @param index 
   * @param e 
   */
  async downloadMedia(index: number, e) {

    this.user.logs.push({
      type: 'info',
      message: 'User downloaded media: ' + e.fullPath,
      created: Date.now()
    })

    await this.userService.AddUserLog(this.user.uid, this.user.logs).toPromise().then(res => { });


    this.mediaService.DownloadMedia(e.fullPath).subscribe(url => {

      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        if (xhr.status == 200) {
          var blob = new Blob([xhr.response], { type: e.contentType as string });
          let a = document.createElement("a");
          document.body.appendChild(a);
          let url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = e.name;
          a.click();
          window.URL.revokeObjectURL(url);
        } else {
          console.log('Unable to download file');
        }
      };
      xhr.open('GET', url as string);
      xhr.send();


    });
  }

}
