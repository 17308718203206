import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { Questionnaire } from '../../../../shared/services/questionnaire';
import { Question } from '../../../../shared/services/goal';
import { GoalService } from '../../../../shared/services/goal.service';
import { QuestionnaireService } from '../../../../shared/services/questionnaire.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from "../../../../shared/services/auth.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";


@Component({
  selector: 'app-questionnaire-editor',
  templateUrl: './questionnaire-editor.component.html',
  styleUrls: ['./questionnaire-editor.component.scss']
})
export class QuestionnaireEditorComponent {

  buttonTitle = "Gruppe erstellen";
  updateMode = false;
  ngoals: number = 0;
  questions: Question[] = [];

  availableGoals = [];

  @Output() questionnaireAdded = new EventEmitter();

  constructor(
    private questionnaireService: QuestionnaireService,
    private snackBar: MatSnackBar,
    public authService: AuthService,
    public goalApi: GoalService,
    @Inject(MAT_DIALOG_DATA) public data: {
      questionnaire: Questionnaire,
    },
    private mdDialogRef: MatDialogRef<QuestionnaireEditorComponent>
  ) { }


  /**
   * Get button title
   */
  getButtonTitle() {
    if (this.data.questionnaire.uid != '') {
      return "Aktualisieren";
    }
    else {
      return "Erstellen"
    }
  }


  /**
   * Create a questionnaire
   */
  createQuestionnaire() {
    // this.data.questionnaire.questions = this.questions;
    if (this.data.questionnaire.uid == '') {
      this.questionnaireService.AddQuestionnaire(this.data.questionnaire).subscribe(() => {
        this.mdDialogRef.close();
      });
    }
    else {
      if (this.data.questionnaire.title != '') {
        this.questionnaireService.AddQuestionnaire(this.data.questionnaire).subscribe(() => {
          this.mdDialogRef.close();
        });
      }
    }
  }

}
