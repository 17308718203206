import { Component } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { ConfirmDialogService } from '../../../shared/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent {

  constructor(
    public authService: AuthService,
    private dialogService: ConfirmDialogService
  ) { }


  /**
   * Reset password
   * @param email 
   */
  resetPassword(email) {
    this.authService.ForgotPassword(email).then((res) => {
      console.log(res);
      this.showAlert("Erfolg", "E-Mail wurde erfolgreich versandt. Bitte überprüfen Sie Ihre Inbox.", "OK", "");
    }).catch((err) => {
      console.log(err);
      this.showAlert("Fehler", "Bitte überprüfen Sie Ihre E-Mail-Addresse und versuchen Sie es erneut.", "OK", "");
    })
  }


  /**
   * Show error alert
   * @param title 
   * @param message 
   * @param confirmText 
   * @param cancelText 
   */
  showAlert(title, message, confirmText, cancelText) {

    this.dialogService.open({
      title: title,
      message: message,
      cancelText: cancelText,
      confirmText: confirmText,
      width: '400px'
    });

  }

}