import { Injectable } from '@angular/core';
import { Group } from './group';
import { User } from './user';
import { AuthService } from "./auth.service";
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class GroupService {

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public authService: AuthService) { }

  /**
   * Add group
   * @param group 
   */
  AddGroup(group: Group) {

    const updateGroup = new Observable(observer => {
      if (group.uid != '') {
        var userRef = this.afs.collection("groups").doc(group.uid);

        userRef.update({
          title: group.title,
          firstGoalGroup: group.firstGoalGroup,
          secondGoalGroup: group.secondGoalGroup,
          unlockCode: group.unlockCode,
          unlockUrl: group.unlockUrl,
          finalUrl: group.finalUrl,
          updated: Date.now()
        })
          .then(function () {
            observer.next(true);
            observer.complete();
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
            observer.next(false);
            observer.complete();
          });
      }
      else {
        this.afs.collection("groups").add(group).then(function () {
          observer.next(true);
          observer.complete();
        })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error("Error creating document: ", error);
            observer.next(false);
            observer.complete();
          });
      }
    });

    return updateGroup;
  }


  /**
   * Check Groups
   * @param name 
   */
  CheckGroup(name: string) {
    const groupList = new Observable(observer => {
      this.afs.collection('groups', ref => ref.where('title', '==', name)).get().toPromise().then(function (querySnapshot) {
        const groups = [];
        querySnapshot.forEach(function (doc) {
          console.log(doc)
          var group = doc.data() as Group;
          group.uid = doc.id;
          groups.push(group);
        });
        observer.next(groups);
        observer.complete();
      });
    });

    return groupList;
  }



  /**
   * Get group by id
   * @param id 
   */
  GetGroup(id: string) {
    const groupList = new Observable(observer => {
      this.afs.collection("groups").doc(id).get().toPromise().then(function (doc) {
        var group = doc.data() as Group;
        group.uid = doc.id;
        observer.next(group);
        observer.complete();
      });
    });

    return groupList;
  }


  /**
   * Get group list
   */
  GetGroupList() {

    const groupList = new Observable(observer => {
      // this.authService.afAuth.authState.subscribe(user => {
      this.afs.collection("groups").get().toPromise().then(function (querySnapshot) {
        const groups = [];
        querySnapshot.forEach(function (doc) {
          var group = doc.data() as Group;
          group.uid = doc.id;
          groups.push(group);
        });
        observer.next(groups);
        observer.complete();
      });
      // });
    });

    return groupList;

  }


  /**
   * Delete group
   * @param id 
   */
  DeleteGroup(id: string) {

    const groupRemoved = new Observable(observer => {
      this.afs.collection("groups").doc(id).delete().then(function () {
        console.log("Document successfully deleted!");
        observer.next(true);
        observer.complete();
      })
        .catch(function (error) {
          console.error("Error removing document: ", error);
          observer.next(false);
          observer.complete();
        });
    });

    return groupRemoved

  }


  /**
   * Get group members
   * @param id 
   */
  GetGroupMembers(groupId: string) {

    const groupMembers = new Observable(observer => {
      this.afs.collection("users", ref => ref.where('group', '==', groupId)).get().toPromise().then(function (querySnapshot) {
        const users = [];
        querySnapshot.forEach(function (doc) {
          users.push(doc.data() as User);
        });

        observer.next(users);
        observer.complete();
      });
    });

    return groupMembers

  }
}