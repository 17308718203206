import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Group } from '../../../../shared/services/group';
import { GoalService } from '../../../../shared/services/goal.service';
import { GroupService } from '../../../../shared/services/group.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from "../../../../shared/services/auth.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";


@Component({
  selector: 'app-group-editor',
  templateUrl: './group-editor.component.html',
  styleUrls: ['./group-editor.component.scss']
})
export class GroupEditorComponent implements OnInit {

  buttonTitle = "Gruppe erstellen";
  updateMode = false;
  ngoals: number = 0;
  goals: string[] = [];

  availableGoals = [];

  @Output() groupAdded = new EventEmitter();

  constructor(
    private groupApi: GroupService,
    private snackBar: MatSnackBar,
    public authService: AuthService,
    public goalApi: GoalService,
    @Inject(MAT_DIALOG_DATA) public data: {
      group: Group,
    },
    private mdDialogRef: MatDialogRef<GroupEditorComponent>
  ) { }

  ngOnInit() {
    // this.goalApi.GetGoalList().subscribe(goals => {
    //   this.availableGoals = goals as Goal[];
    // });
  }

  /**
   * Update Goals array
   */
  // public updateNumberGoals() {
  //   // Check if we already have defined values
  //   var tempValues: Goal[] = [];
  //   if (this.goals.length > 0) {
  //     tempValues = JSON.parse(JSON.stringify(this.goals));
  //   }

  //   // Reset value array and fill with description object
  //   this.goals = [];
  //   var goalObject: Goal = {
  //     uid: '',
  //     title: '',
  //     exercises: [],
  //     created: Date.now(),
  //     updated: Date.now()
  //   };

  //   for (var i = 0; i < this.ngoals; i++) {
  //     if (i < tempValues.length) {
  //       this.goals.push(JSON.parse(JSON.stringify(tempValues[i])));
  //     }
  //     else {
  //       this.goals.push(JSON.parse(JSON.stringify(goalObject)));
  //     }
  //   }
  // }


  /**
   * Edit a group
   * @param group 
   */
  // setGroup(group: Group) {
  //   this.buttonTitle = "Gruppe aktualisieren";
  //   this.updateMode = true;  
  //   this.group = group;
  //   this.goals = JSON.parse(JSON.stringify(group.goals));
  //   this.ngoals = group.goals.length;
  // }


  /**
 * Get button title
 */
  getButtonTitle() {
    if (this.data.group.uid != '') {
      return "Aktualisieren";
    }
    else {
      return "Erstellen"
    }
  }


  /**
   * Create a group
   */
  createGroup() {
    if (this.data.group.uid == '') {
      this.groupApi.AddGroup(this.data.group).subscribe(() => {
        this.mdDialogRef.close();
      });
    }
    else {
      if (this.data.group.title != '') {
        this.groupApi.AddGroup(this.data.group).subscribe(() => {
          this.mdDialogRef.close();
        });
      }
    }
  }


  /**
   * Reset editor
   */
  // resetEditor() {
  //   this.buttonTitle = "Gruppe erstellen";
  //   this.updateMode = false;

  //   this.ngoals = 0;
  //   this.goals.length = 0;

  //   this.group = {
  //     uid: '',
  //     title: '',
  //     goals: [],
  //     created: Date.now(),
  //     updated: Date.now()
  //   }
  // }

}
