import { Component } from '@angular/core';
import { Goal } from '../../../../shared/services/goal';
import { GoalService } from '../../../../shared/services/goal.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { GoalEditorComponent } from '../goal-editor/goal-editor.component';
import { MatDialog } from '@angular/material';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import clonedeep from 'lodash.clonedeep';
import { v4 as uuid } from 'uuid';
import { ConfirmDialogService } from '../../../../shared/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-goal-list',
  templateUrl: './goal-list.component.html',
  styleUrls: ['./goal-list.component.scss']
})
export class GoalListComponent {

  dataSource: MatTableDataSource<Goal>;
  GoalData: any = [];

  constructor(
    private goalApi: GoalService,
    private router: Router,
    public dialog: MatDialog,
    public authService: AuthService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private dialogService: ConfirmDialogService
  ) {
    this.getAllGoals();
  }


  displayedColumns: any[] = [
    'title',
    'action'
  ];


  /**
   * Reload goals
   */
  reloadGoals() {
    this.getAllGoals();
  }


  /**
   * Create Goal
   */
  createGoal() {

    const dialogRef = this.dialog.open(GoalEditorComponent, {
      data: {
        goal: {
          uid: '',
          title: '',
          instruction: '',
          exercises: [],
          created: Date.now(),
          updated: Date.now()
        }
      },
      width: "800px"
    });
    dialogRef.afterClosed().subscribe(confirmresult => { this.reloadGoals() });

  }


  /**
   * Delete goal
   * @param index 
   * @param e 
   */
  deleteGoal(index: number, e) {

    this.dialogService.open({
      title: "Bestätigung",
      message: "Wollen Sie das Lernziel wirklich löschen?",
      cancelText: "NEIN",
      confirmText: "JA",
      width: '400px'
    });

    this.dialogService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.goalApi.DeleteGoal(e.uid).subscribe(value => this.getAllGoals());
      }
    });

  }


  /**
   * Load all goals
   */
  getAllGoals() {
    this.goalApi.GetGoalList().subscribe(goals => {
      this.GoalData = goals as Goal[];
      const sortedGoal = this.GoalData.sort((a, b) => a.created - b.created)
      this.dataSource = new MatTableDataSource(sortedGoal)
    });
  }


  /**
   * Open goal
   * @param index 
   * @param e 
   */
  openGoal(index: number, e) {
    this.router.navigate(['dashboard/goal/' + e.uid], { state: { goal: JSON.stringify(e) } });
  }


  /**
  * Duplicate Goal
  * @param index 
  * @param e 
  */
  duplicateGoal(index: number, e) {
    const dupStore = clonedeep(e) as Goal;
    dupStore.uid = "";
    dupStore.title += " Copy"

    // Replace exercise uuids
    dupStore.exercises.forEach(exercise => {
      exercise.uid = uuid();
    })

    this.goalApi.AddGoal(dupStore).subscribe(() => {
      this.getAllGoals();
    });
  }

}
