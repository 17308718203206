import { Component } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { ConfirmDialogService } from '../../../shared/confirm-dialog/confirm-dialog.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})

export class SignInComponent {

  constructor(
    public authService: AuthService,
    private dialogService: ConfirmDialogService
  ) {
    console.log("Leadership App v" + environment.version);
  }


  /**
   * Sign in
   * @param username 
   * @param password 
   */
  signIn(username, password) {
    this.authService.SignIn(username, password).catch((err) => {
      console.log(err);
      this.showAlert("Fehler", "Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihre E-Mail-Addresse und Ihr Passwort und versuchen Sie es erneut.", "OK", "");
    })
  }


  /**
   * Show error alert
   * @param title 
   * @param message 
   * @param confirmText 
   * @param cancelText 
   */
  showAlert(title, message, confirmText, cancelText) {

    this.dialogService.open({
      title: title,
      message: message,
      cancelText: cancelText,
      confirmText: confirmText,
      width: '400px'
    });

  }

}