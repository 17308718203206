import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { Router } from "@angular/router";
import { MatDialog } from '@angular/material';
import { DataService } from "../../shared/services/data.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public router: Router,
    public dialog: MatDialog,
    private data: DataService
  ) { }

  user = undefined;
  isAdmin = false;
  // opened = true;
  // showMenuTitle = true;

  // @ViewChild("sidenav", { static: false }) sidenav: MatSidenav;

  ngOnInit() {

    this.authService.observableUserDetails.subscribe(value => {
      if (value != null && value != undefined) {
        this.user = value;
        value.getIdTokenResult().then(idTokenResult => {
          if (idTokenResult.claims.admin != undefined) {
            this.isAdmin = true;
            console.log(this.isAdmin);
          }
        });
      }
    });

  }

  /**
   * Navigate Home
   */
  navigateHome() {
    this.router.navigate(['/dashboard']);
  }

  /**
   * Show info
   */
  showInfo() {
    this.data.changeMessage("showInfo")
  }

}
