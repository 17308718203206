import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Goal, Exercise, Question } from '../../../../shared/services/goal';
import { GoalService } from '../../../../shared/services/goal.service';
import { ExerciseEditorComponent } from '../exercise-editor/exercise-editor.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material';
import clonedeep from 'lodash.clonedeep';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { v4 as uuid } from 'uuid';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogService } from '../../../../shared/confirm-dialog/confirm-dialog.service';
import { GoalEditorComponent } from '../goal-editor/goal-editor.component';

@Component({
  selector: 'app-goal-details',
  templateUrl: './goal-details.component.html',
  styleUrls: ['./goal-details.component.scss']
})

export class GoalDetailsComponent {

  @ViewChild(ExerciseEditorComponent, { static: false }) exerciseEditor: ExerciseEditorComponent;

  dataSource: MatTableDataSource<Exercise>;
  exerciseData: any = [];
  orderChanged: boolean = false;
  showEditor: boolean = false;

  displayedColumns: any[] = [
    'title',
    'action'
  ];

  constructor(
    private goalApi: GoalService,
    private router: Router,
    public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private dialogService: ConfirmDialogService,
    private changeDetectorRef: ChangeDetectorRef
  ) {

    if (this.router.getCurrentNavigation() != undefined &&
      this.router.getCurrentNavigation().extras != undefined &&
      this.router.getCurrentNavigation().extras.state != undefined) {
      this.goal = JSON.parse(this.router.getCurrentNavigation().extras.state.goal);
      this.exerciseData = this.goal.exercises as Exercise[];
      this.dataSource = new MatTableDataSource(this.exerciseData)
    }
    else {
      this.router.navigate(['dashboard']);
    }
  }

  goal: Goal = {
    uid: '',
    title: '',
    instruction: '',
    exercises: [],
    created: Date.now(),
    updated: Date.now()
  }


  /**
   * Send upate to backend
   * @param goal 
   */
  updateGoalInfo() {
    this.goalApi.AddGoal(this.goal).subscribe(() => {
      this.goalApi.GetGoal(this.goal.uid).subscribe(res => {
        this.goal = res as Goal;
        this.exerciseData = this.goal.exercises as Exercise[];
        this.dataSource = new MatTableDataSource(this.exerciseData)
      });
    });
  }


  /**
   * New exercise added
   * @param exercise 
   */
  goalExercisesAdded(exercise: Exercise[]) {
    exercise.forEach(element => {

      // Check if we are just updating the exercise
      const index = this.goal.exercises.map(e => e.uid).indexOf(element.uid);
      if (index >= 0) {
        this.goal.exercises[index] = element;
      }
      else {
        this.goal.exercises.push(element);
      }
    }, this);
    this.showEditor = false;
    this.updateGoalInfo();
  }


  /**
   * Add exercise
   */
  addExercise() {
    this.showEditor = true;
  }


  /**
   * Edit goal title
   */
  editGoalTitle() {
    const dialogRef = this.dialog.open(GoalEditorComponent, {
      data: {
        goal: this.goal
      },
      width: "800px"
    });
    dialogRef.afterClosed().subscribe(confirmresult => { });
  }


  /**
   * Delete exercise
   * @param index 
   * @param e 
   */
  deleteExercise(index: number, e) {
    var exerciseIndex = this.goal.exercises.findIndex(function (el) {
      return el['uid'] == e.uid;
    });

    this.dialogService.open({
      title: "Bestätigung",
      message: "Wollen Sie die Übung wirklich löschen?",
      cancelText: "NEIN",
      confirmText: "JA",
      width: '400px'
    });

    this.dialogService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.goal.exercises.splice(exerciseIndex, 1);
        this.updateGoalInfo();
      }
    });

  }


  /**
   * Edit exercise
   * @param exercise 
   */
  editExercise(exercise) {
    this.showEditor = true;
    this.changeDetectorRef.detectChanges();
    this.exerciseEditor.setExercise(exercise);
  }


  /**
   * Duplicate Exercise
   * @param index 
   * @param e 
   */
  duplicateExercise(index: number, e) {
    const dupStore = clonedeep(e) as Exercise;
    dupStore.uid = uuid();
    this.goal.exercises.push(dupStore as never);
    this.updateGoalInfo();
  }


  /**
   * Move element down
   * @param index 
   * @param e 
   */
  moveDown(index: number, e) {
    this.moveItem(index, index + 1);
  }


  /**
   * Move element down
   * @param index 
   * @param e 
   */
  moveUp(index: number, e) {
    this.moveItem(index, index - 1);
  }


  /**
   * Move an item
   * @param data 
   * @param from 
   * @param to 
   */
  moveItem(from, to) {
    let exerciseOrder = JSON.parse(JSON.stringify(this.goal.exercises));
    // remove `from` item and store it
    var f = exerciseOrder.splice(from, 1)[0];
    // insert stored item into position `to`
    exerciseOrder.splice(to, 0, f);
    this.goal.exercises = exerciseOrder;
    this.updateGoalInfo();
  }

}
