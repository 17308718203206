import { Component, ViewChild } from '@angular/core';
import { MediaService } from '../../../../shared/services/media.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { Goal } from '../../../../shared/services/goal';
import { GoalService } from '../../../../shared/services/goal.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MediaEditorComponent } from '../media-editor/media-editor.component';
import { MatDialog } from '@angular/material';
import { ConfirmDialogService } from '../../../../shared/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.scss']
})
export class MediaListComponent {

  @ViewChild(MediaEditorComponent, { static: false }) mediaEditor: MediaEditorComponent;

  dataSource: MatTableDataSource<any>;
  mediaData: any = [];
  goalData: any = [];

  constructor(
    private mediaService: MediaService,
    private router: Router,
    public dialog: MatDialog,
    public authService: AuthService,
    private dialogService: ConfirmDialogService,
    private goalApi: GoalService,
  ) {
    this.getAllMedia();
    this.getAllGoals();
  }


  displayedColumns: any[] = [
    'title',
    'goals',
    'type',
    'action'
  ];


  /**
   * Reload medias
   */
  reloadMedia() {
    this.getAllMedia();
  }


  /**
   * Load all medias
   */
  getAllMedia() {
    this.mediaService.GetAllMedia().subscribe(res => {
      this.mediaData = res as any[]
      this.dataSource = new MatTableDataSource(this.mediaData);
    })
  }


  /**
   * Load all goals
   */
  getAllGoals() {
    this.goalApi.GetGoalList().subscribe(goals => {
      this.goalData = goals as Goal[];
    });
  }


  /**
   * Create Goal
   */
  createMedia() {
    const dialogRef = this.dialog.open(MediaEditorComponent, {
      data: {
        media: this.mediaData
      },
      width: "400px"
    });
    dialogRef.afterClosed().subscribe(confirmresult => { this.reloadMedia() });
  }


  /**
   * Delete media
   * @param index 
   * @param e 
   */
  deleteMedia(index: number, e) {

    this.dialogService.open({
      title: "Bestätigung",
      message: "Wollen Sie die Datei wirklich löschen?",
      cancelText: "NEIN",
      confirmText: "JA",
      width: '400px'
    });

    this.dialogService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.mediaService.DeleteMedia(e.name).subscribe(value => this.getAllMedia());
      }
    });

  }


  /**
   * Download media
   * @param index 
   * @param e 
   */
  downloadMedia(index: number, e) {
    this.mediaService.DownloadMedia(e.fullPath).subscribe(url => {

      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        if (xhr.status == 200) {
          var blob = new Blob([xhr.response], { type: e.contentType as string });
          let a = document.createElement("a");
          document.body.appendChild(a);
          let url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = e.name;
          a.click();
          window.URL.revokeObjectURL(url);
        } else {
          console.log('Unable to download file');
        }
      };
      xhr.open('GET', url as string);
      xhr.send();

    });
  }


  /**
   * Convert goal uids into names
   * @param goalIds 
   */
  getGoalNames(goalIds: string) {
    var goalList = goalIds.split(',');
    var goalNames = [];
    goalList.forEach(goal => {
      var g: Goal = this.goalData.find(g => g.uid == goal);
      if (g != undefined) {
        goalNames.push(g.title);
      }
    });
    goalNames.sort((a, b) => a.localeCompare(b))
    return goalNames.join(', ');
  }

}
