import { Component, Inject } from '@angular/core';
import { MediaService } from '../../../../shared/services/media.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from "../../../../shared/services/auth.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Goal } from '../../../../shared/services/goal';
import { GoalService } from 'src/app/shared/services/goal.service';

@Component({
  selector: 'app-media-editor',
  templateUrl: './media-editor.component.html',
  styleUrls: ['./media-editor.component.scss']
})
export class MediaEditorComponent {

  buttonTitle = "Hochladen";
  mediaFile: File = null;
  uploadStarted: Boolean = false;
  title = "";
  availableGoals = [];
  goals: string[] = [];

  constructor(
    private mediaService: MediaService,
    private snackBar: MatSnackBar,
    public goalApi: GoalService,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: {
      media: [],
    },
    private mdDialogRef: MatDialogRef<MediaEditorComponent>
  ) {

    // Get goal details
    this.goalApi.GetGoalList().subscribe(goals => {
      this.availableGoals = goals as Goal[];
    });
  }


  /**
   * Set upload file
   * @param files 
   */
  handleFileInput(files: FileList) {
    this.mediaFile = files.item(0);
  }

  /**
   * 
   */
  uploadMedia() {

    if (!this.data.media.map(m => m["name"] as string).includes(this.title)) {
      this.uploadStarted = true;
      this.mediaService.UploadMedia(this.title, this.goals, this.mediaFile).subscribe(res => {
        if (res["success"]) {
          this.mdDialogRef.close();
        }
        else {
          this.uploadStarted = false;
          this.snackBar.open('Fehler beim hochladen der Datei. Bitte erneut probieren. Fehler: ' + res["message"], 'OK', {
            duration: 3000
          });
        }
      });
    } else {
      this.snackBar.open('Fehler beim hochladen der Datei: Dateiname existiert bereits.', 'OK', {
        duration: 3000
      });
    }

  }


  /**
   * Set goal
   * @param goal 
   */
  setGoal(goal: Goal) {
    this.goals.includes(goal.uid) ? this.goals.splice(this.goals.indexOf(goal.uid), 1) : this.goals.push(goal.uid);
  }
}
