import { Component } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { ConfirmDialogService } from '../../../shared/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})

export class SignUpComponent {

  constructor(
    public authService: AuthService,
    private dialogService: ConfirmDialogService
  ) { }


  /**
   * Sign up
   * @param mail 
   * @param password 
   * @param repeatPassword 
   * @param code 
   */
  signUp(mail, password, repeatPassword, code) {
    if (code == "LAPPSP2021_") {
      if (password == repeatPassword) {
        this.authService.SignUp(mail, password).catch((err) => {
          console.log(err)
          this.showAlert("Fehler", "Registrierung fehlgeschlagen. Bitte überprüfen Sie Ihre E-Mail-Addresse.", "OK", "");
        })
      }
      else {
        this.showAlert("Fehler", "Passwort stimmt nicht überein.", "OK", "");
      }
    }
    else {
      this.showAlert("Fehler", "Ungültiger Registrierungscode.", "OK", "");
    }
  }


  /**
  * Show error alert
  * @param title 
  * @param message 
  * @param confirmText 
  * @param cancelText 
  */
  showAlert(title, message, confirmText, cancelText) {

    this.dialogService.open({
      title: title,
      message: message,
      cancelText: cancelText,
      confirmText: confirmText,
      width: '400px'
    });

    // this.dialogService.confirmed().subscribe(confirmed => {
    //   alert(confirmed)
    // });  
  }

}