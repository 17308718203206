import { Injectable } from '@angular/core';
import { Questionnaire } from './questionnaire';
import { AuthService } from "./auth.service";
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class QuestionnaireService {

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public authService: AuthService) { }

  /**
   * Add questionnaire
   * @param group 
   */
  AddQuestionnaire(questionnaire: Questionnaire) {

    const updateQuestionnaire = new Observable(observer => {
      if (questionnaire.uid != '') {
        var userRef = this.afs.collection("questionnaires").doc(questionnaire.uid);

        userRef.update({
          title: questionnaire.title,
          questions: questionnaire.questions,
          updated: Date.now()
        })
          .then(function () {
            observer.next(true);
            observer.complete();
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error("Error updating questionnaire: ", error);
            observer.next(false);
            observer.complete();
          });
      }
      else {
        this.afs.collection("questionnaires").add(questionnaire).then(function () {
          observer.next(true);
          observer.complete();
        })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error("Error creating questionnaire: ", error);
            observer.next(false);
            observer.complete();
          });
      }
    });

    return updateQuestionnaire;
  }


  /**
   * Get group list
   */
  GetQuestionnaires() {

    const questionnaireList = new Observable(observer => {
      // this.authService.afAuth.authState.subscribe(user => {
      this.afs.collection("questionnaires").get().toPromise().then(function (querySnapshot) {
        const questionnaires = [];
        querySnapshot.forEach(function (doc) {
          var questionnaire = doc.data() as Questionnaire;
          questionnaire.uid = doc.id;
          questionnaires.push(questionnaire);
        });
        observer.next(questionnaires);
        observer.complete();
      });
      // });
    });

    return questionnaireList;

  }


  /**
   * Delete group
   * @param id 
   */
  DeleteQuestionnaire(id: string) {

    const questionnaireRemoved = new Observable(observer => {
      this.afs.collection("questionnaires").doc(id).delete().then(function () {
        console.log("Document successfully deleted!");
        observer.next(true);
        observer.complete();
      })
        .catch(function (error) {
          console.error("Error removing document: ", error);
          observer.next(false);
          observer.complete();
        });
    });

    return questionnaireRemoved

  }

}