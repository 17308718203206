import { Injectable } from '@angular/core';
import { Goal } from './goal';
import { AuthService } from "./auth.service";
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class GoalService {

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public authService: AuthService) { }


  /**
   * Add goal
   * @param goal 
   */
  AddGoal(goal: Goal) {

    const updateGoal = new Observable(observer => {

      if (goal.uid != '') {
        var userRef = this.afs.collection("goals").doc(goal.uid);

        userRef.update({
          title: goal.title,
          instruction: goal.instruction,
          exercises: goal.exercises,
          updated: Date.now()
        })
          .then(function () {
            observer.next(true);
            observer.complete();
          })
          .catch(function (error) {
            console.error("Error updating document: ", error);
            observer.next(false);
            observer.complete();
          });
      }
      else {
        this.afs.collection("goals").add(goal).then(function () {
          observer.next(true);
          observer.complete();
        })
          .catch(function (error) {
            console.error("Error creating document: ", error);
            observer.next(false);
            observer.complete();
          });
      }
    });

    return updateGoal;
  }


  /**
   * Get goals 
   * @param id 
   */
  GetGoal(id: string) {
    const goalList = new Observable(observer => {
      this.afs.collection("goals").doc(id).get().toPromise().then(function (doc) {
        var goal = doc.data() as Goal;
        goal.uid = doc.id;
        observer.next(goal);
        observer.complete();
      });
    });

    return goalList;
  }


  /**
   * Get goal list
   */
  GetGoalList() {

    const goalList = new Observable(observer => {
      // this.authService.afAuth.authState.subscribe(user => {
      this.afs.collection("goals").get().toPromise().then(function (querySnapshot) {
        const goals = [];
        querySnapshot.forEach(function (doc) {
          var goal = doc.data() as Goal;
          goal.uid = doc.id;
          goals.push(goal);
        });
        observer.next(goals);
        observer.complete();
      });
      // });
    });

    return goalList;

  }


  /**
   * Delete goal 
   * @param id 
   */
  DeleteGoal(id: string) {
    const goalRemoved = new Observable(observer => {
      this.afs.collection("goals").doc(id).delete().then(function () {
        console.log("Document successfully deleted!");
        observer.next(true);
        observer.complete();
      })
        .catch(function (error) {
          console.error("Error removing document: ", error);
          observer.next(false);
          observer.complete();
        });
    });

    return goalRemoved
  }


}