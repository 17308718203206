import { Component, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { Goal, Exercise, Question } from '../../../../shared/services/goal';
import { v4 as uuid } from 'uuid';
import { MatExpansionPanel } from '@angular/material/expansion';

export interface Element {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-exercise-editor',
  templateUrl: './exercise-editor.component.html',
  styleUrls: ['./exercise-editor.component.scss']
})

export class ExerciseEditorComponent {


  @ViewChild("instructionPanel", { static: false }) instructionPanel: MatExpansionPanel;
  @ViewChild("questionPanel", { static: false }) questionPanel: MatExpansionPanel;

  @Output() goalExercisesAdded: EventEmitter<Exercise[]> = new EventEmitter<Exercise[]>();
  @Input() goal: Goal;

  buttonTitle = "Erstellen";
  canImport = false;
  ninstructions: number = 1;
  instructions: string[] = [''];
  nquestions: number = 1;
  questions: Question[] = [];
  updateMode = false;

  exercise: Exercise = {
    uid: '',
    title: '',
    instructions: [],
    questionsTitle: '',
    questions: [],
    created: Date.now(),
    updated: Date.now()
  }

  constructor(
  ) { }


  /**
   * Update Instruction array
   */
  public updateNumberInstructions() {
    // Check if we already have defined values
    var tempValues: string[] = [];
    if (this.instructions.length > 0) {
      tempValues = JSON.parse(JSON.stringify(this.instructions));
    }

    // Reset value array and fill with description object
    this.instructions = [];

    for (var i = 0; i < this.ninstructions; i++) {
      if (i < tempValues.length) {
        this.instructions.push(tempValues[i]);
      }
      else {
        this.instructions.push('');
      }
    }
  }


  /**
   * Update Question array
   */
  public updateNumberQuestions() {
    // Check if we already have defined values
    var tempValues: Question[] = [];
    if (this.questions.length > 0) {
      tempValues = JSON.parse(JSON.stringify(this.questions));
    }

    // Reset value array and fill with description object
    this.questions = [];
    var questionObject: Question = {
      title: '',
      minText: '',
      minValue: 0,
      maxText: '',
      maxValue: 1
    };

    for (var i = 0; i < this.nquestions; i++) {
      if (i < tempValues.length) {
        this.questions.push(JSON.parse(JSON.stringify(tempValues[i])));
      }
      else {
        this.questions.push(JSON.parse(JSON.stringify(questionObject)));
      }
    }
  }


  /**
   * Save exercise
   */
  createExercise() {
    if (this.exercise.uid == '') {
      this.exercise.uid = uuid();
    }

    this.exercise.instructions = this.instructions;
    this.exercise.questions = this.questions;
    this.goalExercisesAdded.emit([this.exercise]);
    this.resetEditor();
  }


  /**
   * Close editor
   */
  closeEditor() {
    this.resetEditor();
    this.goalExercisesAdded.emit([]);
  }


  /**
   * Set Exercise
   * @param exercise 
   */
  setExercise(exercise: Exercise) {
    this.buttonTitle = "Aktualisieren";
    this.updateMode = true;
    this.exercise = JSON.parse(JSON.stringify(exercise));
    this.ninstructions = this.exercise.instructions.length;
    this.instructions = this.exercise.instructions;
    this.nquestions = this.exercise.questions.length;
    this.questions = this.exercise.questions;
  }


  /**
   * Reset Editor
   */
  resetEditor() {

    this.buttonTitle = "Erstellen";
    this.updateMode = false;

    this.nquestions = 0;
    this.questions.length = 0;

    this.ninstructions = 1;
    this.instructions.length = 0;
    this.instructions = [""];

    this.instructionPanel.open();
    this.questionPanel.close();

    this.exercise = {
      uid: '',
      title: '',
      instructions: [''],
      questionsTitle: '',
      questions: [],
      created: Date.now(),
      updated: Date.now()
    }
  }


  trackByFn(index: any, item: any) {
    return index;
  }
}
