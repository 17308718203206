import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

// Required components for which route services to be activated
import { SignInComponent } from '../../components/authentication/sign-in/sign-in.component';
import { SignUpComponent } from '../../components/authentication/sign-up/sign-up.component';
import { DashboardComponent } from '../../components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from '../../components/authentication/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from '../../components/authentication/verify-email/verify-email.component';

// Import canActivate guard services
import { GoalDetailsComponent } from '../../components/admin/goal-editor/goal-details/goal-details.component';
import { GroupListComponent } from '../../components/admin/group-editor/group-list/group-list.component';
import { UserGoalDetailsComponent } from '../../components/user-goals/user-goal-details/user-goal-details.component';
import { UserGoalListComponent } from '../../components/user-goals/user-goal-list/user-goal-list.component';
import { UserQuestionnaireDetailsComponent } from '../../components/user-goals/user-questionnaire-details/user-questionnaire-details.component';

import { QuestionnaireDetailsComponent } from '../../components/admin/questionnaire-editor/questionnaire-details/questionnaire-details.component';
import { AdminComponent } from '../../components/admin/admin.component';

import { AngularFireAuthGuard, hasCustomClaim, customClaims, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { GroupDetailsComponent } from 'src/app/components/admin/group-editor/group-details/group-details.component';

const adminOnly = () => hasCustomClaim('admin');
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['sign-in']);
const redirectLoggedInToDashboard = () => redirectLoggedInTo(['/dashboard']);
const redirectToAdminOrUser = () => pipe(customClaims, map(claims => claims.admin ? ['/dashboard/admin'] : ['/dashboard/user-goals']));

// Include route guard in routes array
const routes: Routes = [
  { path: '', redirectTo: 'sign-in', pathMatch: 'full' },
  { path: 'sign-in', component: SignInComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToDashboard } },
  { path: 'sign-up', component: SignUpComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToDashboard } },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToDashboard } },
  { path: 'verify-email-address', component: VerifyEmailComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      { path: '', component: DashboardComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectToAdminOrUser } },
      { path: 'group-list', component: GroupListComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: adminOnly } },
      { path: 'user-goals', component: UserGoalListComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
      { path: 'user-goal/:id', component: UserGoalDetailsComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
      { path: 'user-questionnaire/:id', component: UserQuestionnaireDetailsComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
      { path: 'admin', component: AdminComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: adminOnly } },
      { path: 'group/:id', component: GroupDetailsComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: adminOnly } },
      { path: 'goal/:id', component: GoalDetailsComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: adminOnly } },
      { path: 'questionnaire/:id', component: QuestionnaireDetailsComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: adminOnly } },


    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }