import { Injectable } from '@angular/core';
import { Log } from './user';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Response } from './response'

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    private db: AngularFireDatabase
  ) { }

  /**
   * Update User
   * @param id 
   * @param kind 
   */
  UpdateUser(id: string, group: string) {
    const userUpdated = new Observable(observer => {
      var userRef = this.afs.collection("users").doc(id);
      userRef.update({
        group: group
      })
        .then(function () {
          observer.next(true);
          observer.complete();
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
          observer.next(false);
          observer.complete();
        });
    });

    return userUpdated
  }


  /**
   * Update goal info for user
   * @param id 
   * @param kind 
   */
  UpdateUserResponses(id: string, responses: Response[]) {
    const userUpdated = new Observable(observer => {
      var userRef = this.afs.collection("users").doc(id);
      userRef.update({
        responses: responses,
      })
        .then(function () {
          observer.next(true);
          observer.complete();
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
          observer.next(false);
          observer.complete();
        });
    });

    return userUpdated
  }


  /**
   * Update part of user
   * @param id 
   * @param kind 
   */
  UpdateUserPart(id: string, part: number) {
    const userUpdated = new Observable(observer => {
      var userRef = this.afs.collection("users").doc(id);
      userRef.update({
        currentPart: part,
      })
        .then(function () {
          observer.next(true);
          observer.complete();
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
          observer.next(false);
          observer.complete();
        });
    });

    return userUpdated
  }


  //  /**
  //  * Update questionnaire info for user
  //  * @param id 
  //  * @param kind 
  //  */
  // UpdateUserQuestionnaireInfo(id: string, questionnaires: QuestionnaireResponse[]) {
  //   const userUpdated = new Observable(observer => {
  //     var userRef = this.afs.collection("users").doc(id);
  //     userRef.update({
  //       questionnaires: questionnaires
  //     })
  //       .then(function () {
  //         observer.next(true);
  //         observer.complete();
  //       })
  //       .catch(function (error) {
  //         // The document probably doesn't exist.
  //         console.error("Error updating document: ", error);
  //         observer.next(false);
  //         observer.complete();
  //       });
  //   });

  //   return userUpdated
  // }


  /**
   * Add user log
   * @param id 
   * @param logs 
   */
  AddUserLog(id: string, logs: Log[]) {
    const userUpdated = new Observable(observer => {
      var userRef = this.afs.collection("users").doc(id);
      userRef.update({
        logs: logs
      })
        .then(function () {
          observer.next(true);
          observer.complete();
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
          observer.next(false);
          observer.complete();
        });
    });

    return userUpdated
  }

}