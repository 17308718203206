import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Questionnaire } from '../../../../shared/services/questionnaire';
import { Question } from '../../../../shared/services/goal';
import { MatDialog } from '@angular/material';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuestionnaireService } from 'src/app/shared/services/questionnaire.service';
import { GoalService } from 'src/app/shared/services/goal.service';
import { QuestionnaireEditorComponent } from '../questionnaire-editor/questionnaire-editor.component';

@Component({
  selector: 'app-questionnaire-details',
  templateUrl: './questionnaire-details.component.html',
  styleUrls: ['./questionnaire-details.component.scss']
})

export class QuestionnaireDetailsComponent {


  nquestions: number = 0;
  questions: Question[] = [];

  constructor(
    private questionnaireService: QuestionnaireService,
    public goalApi: GoalService,
    private router: Router,
    public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private snackBar: MatSnackBar
  ) {

    if (this.router.getCurrentNavigation() != undefined &&

      // Read state info passed via route
      this.router.getCurrentNavigation().extras != undefined &&
      this.router.getCurrentNavigation().extras.state != undefined) {
      this.questionnaire = JSON.parse(this.router.getCurrentNavigation().extras.state.questionnaire);
      this.questions = this.questionnaire.questions;
      this.nquestions = this.questions.length;
    }
    else {
      this.router.navigate(['dashboard']);
    }

  }

  questionnaire: Questionnaire = {
    uid: '',
    title: '',
    questions: [],
    created: Date.now(),
    updated: Date.now()
  }


  /**
   * Update Questions array
   */
  public updateNumberQuestions() {
    // Check if we already have defined values
    var tempValues: Question[] = [];
    if (this.questions.length > 0) {
      tempValues = JSON.parse(JSON.stringify(this.questions));
    }

    // Reset value array and fill with description object
    this.questions = [];
    var questionObject: Question = {
      title: '',
      minText: '',
      minValue: 0,
      maxText: '',
      maxValue: 0
    };

    for (var i = 0; i < this.nquestions; i++) {
      if (i < tempValues.length) {
        this.questions.push(JSON.parse(JSON.stringify(tempValues[i])));
      }
      else {
        this.questions.push(JSON.parse(JSON.stringify(questionObject)));
      }
    }
  }


  /**
   * Save questionnaire
   */
  saveQuestionnaire() {
    this.questionnaire.questions = this.questions;
    this.questionnaireService.AddQuestionnaire(this.questionnaire).subscribe(() => {
      this.router.navigate(['dashboard']);
    });
  }

  /**
   * Edit goal title
   */
  editQuestionnaireTitle() {
    const dialogRef = this.dialog.open(QuestionnaireEditorComponent, {
      data: {
        questionnaire: this.questionnaire
      },
      width: "400px"
    });
    dialogRef.afterClosed().subscribe(confirmresult => { });
  }


  changeValue(question: Question, index: number) {
    this.questions[index] = question;
  }

}
