import { Component, ViewChild } from '@angular/core';
import { Group } from '../../../../shared/services/group';
import { GroupService } from '../../../../shared/services/group.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { GroupEditorComponent } from '../group-editor/group-editor.component';
import { MatDialog } from '@angular/material';
import clonedeep from 'lodash.clonedeep';
import { ConfirmDialogService } from '../../../../shared/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss']
})
export class GroupListComponent {

  @ViewChild(GroupEditorComponent, { static: false }) groupEditor: GroupEditorComponent;

  dataSource: MatTableDataSource<Group>;
  GroupData: any = [];

  constructor(
    private groupApi: GroupService,
    private router: Router,
    public dialog: MatDialog,
    public authService: AuthService,
    private dialogService: ConfirmDialogService
  ) {
    this.getAllGroups();
  }


  displayedColumns: any[] = [
    'title',
    'action'
  ];


  /**
   * Reload groups
   */
  reloadGroups() {
    this.getAllGroups();
  }


  /**
   * Export group
   * @param index 
   * @param e 
   */
  exportGroup(index: number, e: Group) {

    // Create CSV Array
    let csv = [];
    csv.push("User ID;;;");

    // Export Data
    var csvFile;
    var downloadLink;

    // CSV FILE
    var BOM = new Uint8Array([0xEF, 0xBB, 0xBF]);
    csvFile = new Blob([BOM, csv.join("\n")]);
    downloadLink = document.createElement("a");
    downloadLink.download = e.title + "-DataExport.csv";
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();

  }



  /**
   * Load all groups
   */
  getAllGroups() {
    this.groupApi.GetGroupList().subscribe(groups => {
      this.GroupData = groups as Group[];
      const sortedGroup = this.GroupData.sort((a, b) => a.created - b.created)
      this.dataSource = new MatTableDataSource(sortedGroup)
    });
  }


  /**
   * Create Goal
   */
  createGroup() {

    const dialogRef = this.dialog.open(GroupEditorComponent, {
      data: {
        group: {
          uid: '',
          title: '',
          firstGoalGroup: [],
          secondGoalGroup: [],
          unlockCode: '',
          unlockUrl: '',
          finalUrl: '',
          created: Date.now(),
          updated: Date.now()
        }
      },
      width: "400px"
    });
    dialogRef.afterClosed().subscribe(confirmresult => { this.reloadGroups() });

  }


  /**
   * Edit group
   * @param group 
   */
  editGroup(group) {
    this.router.navigate(['/dashboard/group/' + group.uid], { state: { group: JSON.stringify(group) } });
  }


  /**
  * Duplicate Group
  * @param index 
  * @param e 
  */
  duplicateGroup(index: number, e) {
    const dupStore = clonedeep(e) as Group;
    dupStore.uid = "";
    dupStore.title += " Copy"

    this.groupApi.AddGroup(dupStore).subscribe(() => {
      this.getAllGroups();
    });
  }


  /**
   * Delete group
   * @param index 
   * @param e 
   */
  deleteGroup(index: number, e) {

    this.dialogService.open({
      title: "Bestätigung",
      message: "Wollen Sie die Gruppe wirklich löschen?",
      cancelText: "NEIN",
      confirmText: "JA",
      width: '400px'
    });

    this.dialogService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.groupApi.DeleteGroup(e.uid).subscribe(value => this.getAllGroups());
      }
    });

  }

}
