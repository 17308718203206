import { Component } from '@angular/core';
import { Questionnaire } from '../../../../shared/services/questionnaire';
import { QuestionnaireService } from '../../../../shared/services/questionnaire.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { QuestionnaireEditorComponent } from '../questionnaire-editor/questionnaire-editor.component';
import { MatDialog } from '@angular/material';
import clonedeep from 'lodash.clonedeep';
import { ConfirmDialogService } from '../../../../shared/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-questionnaire-list',
  templateUrl: './questionnaire-list.component.html',
  styleUrls: ['./questionnaire-list.component.scss']
})
export class QuestionnaireListComponent {

  dataSource: MatTableDataSource<Questionnaire>;
  questionnaireData: any = [];

  constructor(
    private questionnaireService: QuestionnaireService,
    private router: Router,
    public dialog: MatDialog,
    public authService: AuthService,
    private dialogService: ConfirmDialogService
  ) {
    this.getAllQuestionnaires();
  }


  displayedColumns: any[] = [
    'title',
    'action'
  ];


  /**
   * Reload questionnaires
   */
  reloadQuestionnaires() {
    this.getAllQuestionnaires();
  }


  /**
   * Load all questionnaires
   */
  getAllQuestionnaires() {
    this.questionnaireService.GetQuestionnaires().subscribe(questionnaires => {
      this.questionnaireData = questionnaires as Questionnaire[];
      const sortedQuestionnaire = this.questionnaireData.sort((a, b) => a.created - b.created)
      this.dataSource = new MatTableDataSource(sortedQuestionnaire)
    });
  }


  /**
   * Create Goal
   */
  createQuestionnaire() {

    const dialogRef = this.dialog.open(QuestionnaireEditorComponent, {
      data: {
        questionnaire: {
          uid: '',
          title: '',
          questions: [],
          created: Date.now(),
          updated: Date.now()
        }
      },
      width: "400px"
    });
    dialogRef.afterClosed().subscribe(confirmresult => { this.reloadQuestionnaires() });

  }


  /**
   * Edit questionnaire
   * @param questionnaire 
   */
  editQuestionnaire(questionnaire) {
    this.router.navigate(['/dashboard/questionnaire/' + questionnaire.uid], { state: { questionnaire: JSON.stringify(questionnaire) } });
  }


  /**
  * Duplicate questionnaire
  * @param index 
  * @param e 
  */
  duplicateQuestionnaire(index: number, e) {
    const dupStore = clonedeep(e) as Questionnaire;
    dupStore.uid = "";
    dupStore.title += " Copy"

    this.questionnaireService.AddQuestionnaire(dupStore).subscribe(() => {
      this.getAllQuestionnaires();
    });
  }


  /**
   * Delete questionnaire
   * @param index 
   * @param e 
   */
  deleteQuestionnaire(index: number, e) {

    this.dialogService.open({
      title: "Bestätigung",
      message: "Wollen Sie den Fragebogen wirklich löschen?",
      cancelText: "NEIN",
      confirmText: "JA",
      width: '400px'
    });

    this.dialogService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.questionnaireService.DeleteQuestionnaire(e.uid).subscribe(value => this.getAllQuestionnaires());
      }
    });

  }

}
